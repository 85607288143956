import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/button";
import "../../../styles/pages/postulationState.scss";
import MainContainer from "../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { generalStatesPostulation } from "../../../constants/enum";

const ConfirmationModal = ({
  open,
  close,
  saveInterviewWwceFun,
  generalState,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    close();
  };

  const activate = () => {
    saveInterviewWwceFun();
    close();
  };

  const getTitle = () => {
    switch (generalState) {
      case generalStatesPostulation.interviewWwcePending:
        return (
          <>
            <h1 className="title">{t("interview.confirmationTitle")}</h1>
          </>
        );
      case generalStatesPostulation.mockInterviewPending:
        return (
          <>
            <h1 className="title">{t("mockInterview.confirmationTitle")}</h1>
          </>
        );
      case generalStatesPostulation.preTripTrainingPending:
        return (
          <>
            <h1 className="title">{t("tripTraining.confirmationTitle")}</h1>
          </>
        );
      default:
        return "";
    }
  };
  const getBodyText = () => {
    switch (generalState) {
      case generalStatesPostulation.interviewWwcePending:
        return (
          <>
            <p>{t("interview.confirmationBody")}</p>
          </>
        );
      case generalStatesPostulation.mockInterviewPending:
        return (
          <>
            <p>{t("mockInterview.confirmationBody")}</p>
          </>
        );
      case generalStatesPostulation.preTripTrainingPending:
        return (
          <>
            <p>{t("tripTraining.confirmationBody")}</p>
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer showImage={false} class="ww-container-m">
          {getTitle()}
          <div
            style={{
              padding: "0 25px 0 25px",
            }}
          >
            {getBodyText()}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="ww-btn-secondary"
              size="btn-xs secondary"
              label={t("main.close")}
              onClick={handleClose}
            />
            <Button
              size="btn-xs secondary"
              label={t("pushNotification.BtnConfirmation")}
              onClick={activate}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
