import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/button";
import { BiCommentDetail } from "react-icons/bi";
import { AiFillEye, AiOutlineForm } from "react-icons/ai";
import { ImStack } from "react-icons/im";
import "../../../styles/pages/postulationState.scss";
import { FormSteps, generalStatesPostulation } from "../../../constants/enum";
import CourseSelectLineProcess from "./courseSelectLineProgress";
import InterviewModal from "./interviewModal";
import UploadInformationFlight from "./uploadInformationFlight";
import {
  callApi,
  saveGeneralStatePostulation,
  saveInterviewWwce,
  saveMockInterview,
} from "../../../services/apiService";
import FileListModal from "./fileListModal";
import JobPreferencesModal from "./jobPreferencesModal";
import EmployerListModal from "../../userList/employerListModal";
import VideoAndCvModal from "./cv/videoAndCvModal";
import CvModal from "../../userList/cvModal";
import { getUserInfo } from "../../../services/authService";
import ConfirmationModal from "./confirmationModal";
import { Grid } from "@mui/material";

const CourseSelectResume = ({
  generalState,
  dataPostulation,
  setState,
  setAlertModalNotification,
  setVideoUrlModal,
  getMessagesPostulationFunction,
  setGeneralState,
}) => {
  const { t } = useTranslation();
  const [interviewState, setInterviewState] = useState(false);
  const [videoState, setVideoState] = useState(false);
  const [openInterviewsModal, setOpenInterviewsModal] = useState(false);
  const [openFileListModal, setOpenFileListModal] = useState(false);
  const [openJobPreferencesModal, setOpenJobPreferencesModal] = useState(false);
  const [openEmployerList, setOpenEmployerList] = useState(false);
  const [openVideoCvModal, setOpenVideoCvModal] = useState(false);
  const [openCvModal, setOpenCvModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isBlockedCV, setIsBlockedCV] = useState(false);
  console.log("generalState", generalState);

  const [openInformationFligthModal, setOpenInformationFligthModal] =
    useState(false);
  const [statePostulationModal, setStatePostulationModal] = useState(null);

  const closeHandleInterviewsModal = () => {
    setOpenInterviewsModal(false);
  };
  const closeHandleInformationFligthModal = () => {
    setOpenInformationFligthModal(false);
  };
  const openHandleInterviewsModal = () => {
    setOpenInterviewsModal(true);
  };
  const openHandleInformationFligthModal = () => {
    setOpenInformationFligthModal(true);
  };
  const openHandleFileListModal = () => {
    setOpenFileListModal(true);
  };
  const closeHandleFileListModal = () => {
    setOpenFileListModal(false);
  };
  const openeHandleJobPreferencesModal = () => {
    setOpenJobPreferencesModal(true);
  };
  const closeHandleJobPreferencesModal = () => {
    setOpenJobPreferencesModal(false);
  };
  const handleOpenEmployerList = () => setOpenEmployerList(true);
  const handleCloseEmployerList = () => setOpenEmployerList(false);
  const handleOpenVideoCvModal = () => setOpenVideoCvModal(true);
  const handleCloseVideoCvModal = () => setOpenVideoCvModal(false);
  const handleOpenCvModal = () => setOpenCvModal(true);
  const handleCloseCvModal = () => setOpenCvModal(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const getPill = (x) => {
    switch (x) {
      case "state.approved":
      case "interview.aproved":
      case "video.stateV":
        return "green";
      case "interview.scheduled":
      case "interview.interviewed":
      case "interview.inStudy":
      case "state.revision":
      case "video.stateI":
      case "state.finalized":
        return "blue";
      case "state.pendingToSend":
        return "yellow";
      case "interview.declined":
      case "video.stateVI":
        return "red";
      default:
        return "orange";
    }
  };

  const saveInterviewWwceFun = () => {
    var dataToSend = {
      postulationId: dataPostulation.postulationId,
    };
    callApi(() => {
      saveInterviewWwce(dataToSend);
      setGeneralState(generalStatesPostulation.interviewWwce);
    });
  };

  const saveMockInterviewFun = () => {
    var dataToSend = {
      postulationId: dataPostulation.postulationId,
    };
    callApi(() => {
      saveMockInterview(dataToSend);
      setGeneralState(generalStatesPostulation.mockInterview);
    });
  };

  const savePreTripTrainingFun = () => {
    var dataToSend = {
      postulationId: dataPostulation.postulationId,
      statePostulation: generalStatesPostulation.preTripTraining,
    };
    callApi(() => {
      saveGeneralStatePostulation(dataToSend);
      setGeneralState(generalStatesPostulation.preTripTraining);
    });
  };

  const getProp = (obj, prop) => {
    if (!obj) return "";
    const propValue = obj[prop];
    return propValue ? propValue : "";
  };

  return (
    <>
      <div>
        {generalState > 0 ? (
          <div className="postulation-state-container">
            <div>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} s={8} md={8}>
                  <h1 className="title">{t("postulationState.title")}</h1>
                </Grid>
                <Grid item xs={12} s={4} md={4}>
                  <div className="tittle-btn-container">
                    <div
                      className="styled-button"
                      onClick={() => {
                        setState(
                          generalStatesPostulation.postulationFormPending
                        );
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <AiOutlineForm className="button-icon" />
                    </div>
                    <div
                      className="styled-button"
                      onClick={() => {
                        openHandleFileListModal();
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <ImStack className="button-icon" />
                    </div>
                    <div
                      className="styled-button"
                      onClick={() => {
                        getMessagesPostulationFunction(
                          getProp(dataPostulation, "postulationId")
                        );
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <BiCommentDetail className="button-icon" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* STATES */}
            <br />
            <div>
              <div className="course-name">
                <p>
                  <b>{t("postulationState.CourseName")}: </b>
                  {getProp(dataPostulation, "courseName")}
                </p>
              </div>
            </div>
            {generalState >=
              generalStatesPostulation.postulationFormPending && (
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} md={4}>
                    <div style={{ display: "flex" }}>
                      <p>{t("postulationState.FormState")}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6}>
                        <div className="container-centered margin-1">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className={`pill ${
                                generalState >
                                generalStatesPostulation.uploadingFiles
                                  ? "green"
                                  : generalState ==
                                    generalStatesPostulation.uploadingFiles
                                  ? "blue"
                                  : "orange"
                              }`}
                            >
                              {generalState >
                              generalStatesPostulation.uploadingFiles
                                ? t("postulation.approved")
                                : generalState ==
                                  generalStatesPostulation.uploadingFiles
                                ? t("postulation.revision")
                                : t("postulation.pending")}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="container-centered">
                          {(generalState ==
                            generalStatesPostulation.postulationFormPending ||
                            generalState ==
                              generalStatesPostulation.uploadingFilesPending) && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p
                                onClick={() => {
                                  if (
                                    generalState ==
                                    generalStatesPostulation.postulationFormPending
                                  ) {
                                    setState(FormSteps.PassportDetails);
                                  } else {
                                    setState(FormSteps.GeneralConditions);
                                  }
                                }}
                                className={`pill pointer ${getPill(
                                  getProp(dataPostulation, "formFilesStateName")
                                )}`}
                              >
                                {t("main.correct")}
                              </p>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            {generalState >= generalStatesPostulation.interviewWwcePending && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={6} md={4}>
                  <div style={{ display: "flex" }}>
                    <p>{t("postulationState.InterviewState")}</p>
                  </div>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ paddingTop: "0 !important" }}
                  >
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className={`pill ${
                              generalState >
                              generalStatesPostulation.interviewWwce
                                ? "green"
                                : generalState ==
                                  generalStatesPostulation.interviewWwce
                                ? "blue"
                                : "orange"
                            }`}
                          >
                            {generalState >
                            generalStatesPostulation.interviewWwce
                              ? t("postulation.approved")
                              : generalState ==
                                generalStatesPostulation.interviewWwce
                              ? t("postulation.revision")
                              : t("postulation.pending")}
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.interviewWwcePending && (
                          <div
                            className="mini-container"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <a
                              href="https://outlook.office365.com/book/Entrevistas@worldwidece.com/"
                              className={`pill pointer yellow`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("interview.schedule")}
                            </a>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.interviewWwcePending && (
                          <div
                            className="mini-container"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <p
                              onClick={() => handleOpenConfirmationModal()}
                              className={`pill pointer yellow`}
                            >
                              {t("interview.scheduled")}
                            </p>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {generalState >= generalStatesPostulation.videoAndCvPending && (
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} md={4}>
                    <div style={{ display: "flex" }}>
                      <p className="margin-1">
                        {t("postulationState.VideState")}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} md={6}>
                        <div className="container-centered margin-1">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className={`pill ${
                                generalState >
                                generalStatesPostulation.videoAndCv
                                  ? "green"
                                  : generalState ==
                                    generalStatesPostulation.videoAndCv
                                  ? "blue"
                                  : "orange"
                              }`}
                            >
                              {generalState >
                              generalStatesPostulation.videoAndCv
                                ? t("postulation.approved")
                                : generalState ==
                                  generalStatesPostulation.videoAndCv
                                ? t("postulation.revision")
                                : t("postulation.pending")}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="container-centered margin-1">
                          {generalState ==
                            generalStatesPostulation.videoAndCvPending && (
                            <p
                              style={{
                                display: `${videoState ? "none" : "flex"}`,
                                textAlign: "center",
                              }}
                              onClick={() => {
                                handleOpenVideoCvModal();
                              }}
                              className={`pill pointer yellow`}
                            >
                              {t("video.upload")}
                            </p>
                          )}
                        </div>
                        <div className="container-centered margin-1">
                          {generalState >
                            generalStatesPostulation.videoAndCvPending && (
                            <>
                              <p
                                style={{
                                  display: `${videoState ? "none" : "flex"}`,
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  setIsBlockedCV(true);
                                  handleOpenCvModal();
                                }}
                                className={`pill pointer yellow`}
                              >
                                {<AiFillEye />}
                              </p>
                              <p
                                style={{
                                  display: `${videoState ? "none" : "flex"}`,
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  setIsBlockedCV(false);
                                  handleOpenCvModal();
                                }}
                                className={`pill pointer yellow`}
                              >
                                {<AiOutlineForm />}
                              </p>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            {generalState >= generalStatesPostulation.mockInterviewPending && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={6} md={4}>
                  <div style={{ display: "flex" }}>
                    <p>{t("postulationState.MockInterviewState")}</p>
                  </div>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ paddingTop: "0 !important" }}
                  >
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className={`pill ${
                              generalState >
                              generalStatesPostulation.mockInterview
                                ? "green"
                                : generalState ==
                                  generalStatesPostulation.mockInterview
                                ? "blue"
                                : "orange"
                            }`}
                          >
                            {generalState >
                            generalStatesPostulation.mockInterview
                              ? t("postulation.approved")
                              : generalState ==
                                generalStatesPostulation.mockInterview
                              ? t("postulation.revision")
                              : t("postulation.pending")}
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.mockInterviewPending && (
                          <div
                            className="mini-container"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <a
                              href="https://outlook.office365.com/book/SimulacrosJobInterview@worldwidece.com/"
                              className={`pill pointer yellow`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("mockInterview.schedule")}
                            </a>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.mockInterviewPending && (
                          <div
                            className="mini-container"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <p
                              onClick={() => handleOpenConfirmationModal()}
                              className={`pill pointer yellow`}
                            >
                              {t("mockInterview.scheduled")}
                            </p>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {generalState >=
              generalStatesPostulation.employerSelectionPending && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={6} md={4}>
                  <div>
                    <p>{t("postulationState.employerSelection")}</p>
                  </div>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className={`pill ${
                              generalState >
                              generalStatesPostulation.employerSelection
                                ? "green"
                                : generalState ==
                                  generalStatesPostulation.employerSelection
                                ? "blue"
                                : "orange"
                            }`}
                          >
                            {generalState >
                            generalStatesPostulation.employerSelection
                              ? t("postulation.approved")
                              : generalState ==
                                generalStatesPostulation.employerSelection
                              ? t("postulation.revision")
                              : t("postulation.pending")}
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        {generalState ==
                          generalStatesPostulation.employerSelectionPending && (
                          <p
                            className={`pill pointer yellow`}
                            style={{
                              textAlign: "center",
                            }}
                            onClick={() => {
                              openeHandleJobPreferencesModal();
                            }}
                          >
                            {t("postulation.employers")}
                          </p>
                        )}
                        {generalState >=
                          generalStatesPostulation.employerSelection && (
                          <p
                            className={`pill pointer yellow`}
                            style={{
                              textAlign: "center",
                            }}
                            onClick={() => {
                              handleOpenEmployerList();
                            }}
                          >
                            {t("postulation.employers")}
                          </p>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {generalState >= generalStatesPostulation.interviewWorkPending && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={6} md={4}>
                  <div>
                    <p>{t("postulationState.interviewWork")}</p>
                  </div>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className={`pill ${
                              generalState >
                              generalStatesPostulation.interviewWork
                                ? "green"
                                : generalState ==
                                  generalStatesPostulation.interviewWork
                                ? "blue"
                                : "orange"
                            }`}
                          >
                            {generalState >
                            generalStatesPostulation.interviewWork
                              ? t("postulation.approved")
                              : generalState ==
                                generalStatesPostulation.interviewWork
                              ? t("postulation.revision")
                              : t("postulation.pending")}
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.interviewWorkPending && (
                          <p
                            className={`pill pointer yellow`}
                            onClick={() => {
                              setStatePostulationModal(
                                generalStatesPostulation.interviewWork
                              );
                              openHandleInterviewsModal();
                            }}
                          >
                            {t("postulation.interviewDateSelect")}
                          </p>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {generalState >= generalStatesPostulation.jobOffer && (
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} md={4}>
                    <div>
                      <p>{t("postulationState.jobOffer")}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className={`pill ${
                              generalState > generalStatesPostulation.jobOffer
                                ? "green"
                                : generalState ==
                                  generalStatesPostulation.jobOffer
                                ? "blue"
                                : "orange"
                            }`}
                          >
                            {generalState > generalStatesPostulation.jobOffer
                              ? t("postulation.approved")
                              : generalState ==
                                generalStatesPostulation.jobOffer
                              ? t("postulation.revision")
                              : t("postulation.pending")}
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            {generalState >=
              generalStatesPostulation.interviewConsularPending && (
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} md={4}>
                    <div>
                      <p>{t("postulationState.interviewConsular")}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} md={6}>
                        <div className="container-centered margin-1">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className={`pill ${
                                generalState >
                                generalStatesPostulation.interviewConsular
                                  ? "green"
                                  : generalState ==
                                    generalStatesPostulation.interviewConsular
                                  ? "blue"
                                  : "orange"
                              }`}
                            >
                              {generalState >
                              generalStatesPostulation.interviewConsular
                                ? t("postulation.approved")
                                : generalState ==
                                  generalStatesPostulation.interviewConsular
                                ? t("postulation.revision")
                                : t("postulation.pending")}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="container-centered">
                          {generalState ==
                            generalStatesPostulation.interviewConsularPending && (
                            <p
                              style={{
                                textAlign: "center",
                              }}
                              className={`pill pointer yellow`}
                              onClick={() => {
                                setStatePostulationModal(
                                  generalStatesPostulation.interviewConsular
                                );
                                openHandleInterviewsModal();
                              }}
                            >
                              {t("postulation.interviewDateSelect")}
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            {generalState >=
              generalStatesPostulation.flightAndAccommodationPending && (
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} md={4}>
                    <div>
                      <p>{t("postulationState.flightAndAccommodation")}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} md={6}>
                        <div className="container-centered margin-1">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className={`pill ${
                                generalState >
                                generalStatesPostulation.flightAndAccommodation
                                  ? "green"
                                  : generalState ==
                                    generalStatesPostulation.flightAndAccommodation
                                  ? "blue"
                                  : "orange"
                              }`}
                            >
                              {generalState >
                              generalStatesPostulation.flightAndAccommodation
                                ? t("postulation.approved")
                                : generalState ==
                                  generalStatesPostulation.flightAndAccommodation
                                ? t("postulation.revision")
                                : t("postulation.pending")}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="container-centered">
                          {generalState ==
                            generalStatesPostulation.flightAndAccommodationPending && (
                            <Button
                              label={t("postulation.flightReservationUpload")}
                              onClick={() => {
                                setStatePostulationModal(
                                  generalStatesPostulation.flightAndAccommodation
                                );
                                openHandleInformationFligthModal();
                              }}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            {generalState >=
              generalStatesPostulation.preTripTrainingPending && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={6} md={4}>
                  <div>
                    <p>{t("general.preTripTraining")}</p>
                  </div>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ paddingTop: "0 !important" }}
                  >
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <p
                          className={`pill ${
                            generalState >
                            generalStatesPostulation.preTripTraining
                              ? "green"
                              : generalState ==
                                generalStatesPostulation.preTripTraining
                              ? "blue"
                              : "orange"
                          }`}
                        >
                          {generalState >
                          generalStatesPostulation.preTripTraining
                            ? t("postulation.approved")
                            : generalState ==
                              generalStatesPostulation.preTripTraining
                            ? t("postulation.revision")
                            : t("postulation.pending")}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.preTripTrainingPending && (
                          <div
                            className="mini-container"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <a
                              href="https://outlook.office365.com/book/PreDepartureOrientationWORLDWIDECE@worldwidece.com/"
                              className={`pill pointer yellow`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("tripTraining.schedule")}
                            </a>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="container-centered">
                        {generalState ==
                          generalStatesPostulation.preTripTrainingPending && (
                          <div
                            className="mini-container"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <p
                              onClick={() => handleOpenConfirmationModal()}
                              className={`pill pointer yellow`}
                            >
                              {t("mockInterview.scheduled")}
                            </p>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {generalState >= generalStatesPostulation.pendingAnswer && (
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} md={4}>
                    <div>
                      <p>{t("general.pendingAnswer")}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid item xs={12} md={6}>
                      <div className="container-centered margin-1">
                        <p
                          className={`pill ${
                            generalState >
                            generalStatesPostulation.preTripTraining
                              ? "green"
                              : generalState ==
                                generalStatesPostulation.preTripTraining
                              ? "blue"
                              : "orange"
                          }`}
                        >
                          {generalState > generalStatesPostulation.pendingAnswer
                            ? t("postulation.approved")
                            : generalState ==
                              generalStatesPostulation.pendingAnswer
                            ? t("postulation.revision")
                            : t("postulation.pending")}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <div className="progress-component">
              <CourseSelectLineProcess generalState={generalState} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <InterviewModal
        generalState={generalState}
        openInterviewsModal={openInterviewsModal}
        closeHandleInterviewsModal={closeHandleInterviewsModal}
        statePostulationModal={statePostulationModal}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
        setState={setState}
        setGeneralState={setGeneralState}
      />
      <UploadInformationFlight
        generalState={generalState}
        openInformationFligthModal={openInformationFligthModal}
        closeHandleInformationFligthModal={closeHandleInformationFligthModal}
        statePostulationModal={statePostulationModal}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
        setGeneralState={setGeneralState}
      />
      <FileListModal
        openFileListModal={openFileListModal}
        closeHandleFileListModal={closeHandleFileListModal}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
      />
      <JobPreferencesModal
        openJobPreferencesModal={openJobPreferencesModal}
        closeHandleJobPreferencesModal={closeHandleJobPreferencesModal}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
        setGeneralState={setGeneralState}
        userId={getUserInfo().id}
      />
      {/* MODAL EMPLOYER LIST */}
      <EmployerListModal
        openEmployerList={openEmployerList}
        handleCloseEmployerList={handleCloseEmployerList}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
      />
      <VideoAndCvModal
        handleCloseVideoCvModal={handleCloseVideoCvModal}
        openVideoCvModal={openVideoCvModal}
        setGeneralState={setGeneralState}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
      />
      <CvModal
        open={openCvModal}
        close={handleCloseCvModal}
        postulationId={dataPostulation ? dataPostulation.postulationId : 0}
        userId={getUserInfo().id}
        isBlocked={isBlockedCV}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        close={handleCloseConfirmationModal}
        saveInterviewWwceFun={
          generalState == generalStatesPostulation.mockInterviewPending
            ? saveMockInterviewFun
            : generalState == generalStatesPostulation.preTripTrainingPending
            ? savePreTripTrainingFun
            : saveInterviewWwceFun
        }
        generalState={generalState}
      />
    </>
  );
};

export default CourseSelectResume;
