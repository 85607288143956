import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/button";
import "../../../styles/pages/postulationState.scss";
import { FileTypeId, generalStatesPostulation } from "../../../constants/enum";
import {
  callApi,
  saveInformationFligth,
  getInformationFligth,
} from "../../../services/apiService";
import { FormProvider, useForm } from "react-hook-form";
import MainContainer from "../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import { getUserInfo } from "../../../services/authService";
import DateInput from "../../../components/forms/inputs/dateInput";

const UploadInformationFlight = ({
  generalState,
  openInformationFligthModal,
  closeHandleInformationFligthModal,
  statePostulationModal,
  postulationId,
  setGeneralState,
}) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const [dateInterview, setDateInterview] = useState(null);
  const [interviewsId, setInterviewsId] = useState(0);
  const [currentId, setCurrentId] = useState(0);

  useEffect(() => {
    setBotonActivo(false);
    if (!openInformationFligthModal) {
      setDateInterview(null);
      return;
    }
    if (
      postulationId == null ||
      postulationId == 0 ||
      !openInformationFligthModal
    ) {
      return;
    }
    const dataToGet = {
      postulationId: parseInt(postulationId),
    };
    callApi(
      () => getInformationFligth(dataToGet),
      (data) => {
        loadData(data);
      }
    );
  }, [openInformationFligthModal]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const validations = {
    lodging: {
      required: { value: true, message: t("healthInfo.required") },
    },
    dateOutboundFlight: {
      required: { value: true, message: t("healthInfo.required") },
    },
  };

  const handleClose = () => {
    setValue("lodging", "");
    setBotonActivo(false);
    reset();
    closeHandleInformationFligthModal();
  };

  const onSubmit = handleSubmit((data) => {
    const dateReturnFlightValue =
      data.dateReturnFlight == "" ||
      data.dateReturnFlight == undefined ||
      data.dateReturnFlight == null
        ? null
        : new Date(data.dateReturnFlight);

    if (data.dateReturnFlight) {
      data.dateReturnFlight.setHours(0, 0, 0, 0);
    }

    const dateOutboundFlightValue = new Date(data.dateOutboundFlight);
    dateOutboundFlightValue.setHours(0, 0, 0, 0);

    var userInfo = getUserInfo();
    var dataToSave = new FormData();
    dataToSave.append("id", currentId);
    dataToSave.append("userId", parseInt(userInfo.id));
    dataToSave.append("postulationId", postulationId);
    dataToSave.append("image", data.image[0]);
    dataToSave.append("file_type_id", parseInt(FileTypeId.Flight));
    dataToSave.append("lodging", data.lodging);
    dataToSave.append(
      "dateOutboundFlight",
      dateOutboundFlightValue.toISOString().split("T")[0]
    );
    dataToSave.append(
      "dateReturnFlight",
      dateReturnFlightValue
        ? dateReturnFlightValue.toISOString().split("T")[0]
        : null
    );
    callApi(
      () => saveInformationFligth(dataToSave),
      () => {
        NotificationManager.success(t("form.success"));
        setBotonActivo(false);
        setGeneralState(generalStatesPostulation.flightAndAccommodation);
        handleClose();
      }
    );
  });

  const loadData = (obj) => {
    setValue("lodging", !obj || !obj.lodging ? "" : obj.lodging);
    setValue(
      "dateOutboundFlight",
      !obj || !obj.dateOutbound ? "" : new Date(obj.dateOutbound)
    );
    setValue(
      "dateReturnFlight",
      !obj || !obj.dateReturn ? "" : new Date(obj.dateReturn)
    );
    setCurrentId(obj && obj.id ? parseInt(obj.id) : 0);
  };

  useEffect(() => {
    setBotonActivo(false);
  }, []);

  const dateOutboundFlightValue = watch("dateOutboundFlight");
  const dateReturnFlightValue = watch("dateReturnFlight");
  useEffect(() => {
    if (
      dateReturnFlightValue == null ||
      dateReturnFlightValue > dateOutboundFlightValue
    ) {
      return;
    } else {
      setValue("dateReturnFlight", null);
    }
  }, [dateOutboundFlightValue]);

  return (
    <>
      <Modal
        open={openInformationFligthModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <h1 className="title">
            {t("postulationState.flightAndAccommodation")}
          </h1>
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <div className="mb-3">
                <label>
                  {t("postulation.direction")}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  placeholder={t("register.direction")}
                  className="form-control mb-2"
                  name="lodging"
                  {...register("lodging", validations.lodging)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.lodging && errors.lodging.message}
                </span>
              </div>
              <div className="mb-3">
                <DateInput
                  name="dateOutboundFlight"
                  control={control}
                  placeholder={t("flight.outboundFlight")}
                  label={
                    <label>
                      {t("flight.outboundFlight")}
                      <span className="text-danger"> *</span>
                    </label>
                  }
                  {...register(
                    "dateOutboundFlight",
                    validations.dateOutboundFlight
                  )}
                />
                <span className="text-danger text-small d-block mb-2">
                  {errors.dateOutboundFlight &&
                    errors.dateOutboundFlight.message}
                </span>
              </div>
              <div className="mb-3">
                <DateInput
                  name="dateReturnFlight"
                  control={control}
                  placeholder={t("flight.returnFlight")}
                  minDate={dateOutboundFlightValue}
                  label={<label>{t("flight.returnFlight")}</label>}
                  {...register("dateReturnFlight", validations.dateInterview)}
                />
              </div>
              <div className="mb-3">
                <label>{t("postulation.flightReservation")}</label>
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => handleClose()}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default UploadInformationFlight;
