import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";
import { typeFiles } from "../../constants/enum";

const PreTripTraining = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "helpPreTripTraining.file1",
      description:"helpPreTripTraining.description1",
      url: "download/PreTripTraining/¿QUÉ NECESITAS SABER ANTES DE VIAJAR A USA.pdf",
    },
    {
      name: "helpPreTripTraining.file2",
      description:"helpPreTripTraining.description2",
      url: "download/PreTripTraining/Instrucciones para bajar I 94.pdf",
    },
    {
      name: "helpPreTripTraining.file3",
      description:"helpPreTripTraining.description3",
      url: "download/PreTripTraining/LINK GRABACIÓN CAPACITACIÓN PRE VIAJE.pdf",
    },
    {
      name: "helpPreTripTraining.file4",
      description:"helpPreTripTraining.description4",
      url: "download/PreTripTraining/TRAVEL TIPS.pdf",
    },
    {
      name: "helpPreTripTraining.file5",
      description:"helpPreTripTraining.description5",
      url: "download/PreTripTraining/Social Security Aplication/Social Security - links.pdf",
    },
    {
      name: "helpPreTripTraining.file6",
      description:"helpPreTripTraining.description6",
      url: "download/PreTripTraining/IENA/IENA SWT Handbook .pdf",
    },
    {
      name: "helpPreTripTraining.file7",
      description:"helpPreTripTraining.description7",
      url: "download/PreTripTraining/IENA/IENA Welcome Letter.pdf",
    },
    {
      name: "helpPreTripTraining.file8",
      description:"helpPreTripTraining.description8",
      url: "download/PreTripTraining/IENA/IENA_J1_Work_Travel_Handbook.pdf",
    },
    {
      name: "helpPreTripTraining.file9",
      description:"helpPreTripTraining.description9",
      url: "download/PreTripTraining/IENA/SWT Welcome Brochure.pdf",
    },
    {
      name: "helpPreTripTraining.file10",
      description:"helpPreTripTraining.description10",
      url: "download/PreTripTraining/IENA/Travel Authorization Instructions and Request Form (1).pdf",
    },
    {
      name: "helpPreTripTraining.file11",
      description:"helpPreTripTraining.description11",
      url: "download/PreTripTraining/IENA/U.S. Department of State Welcome Letter.pdf",
    },
    {
      name: "helpPreTripTraining.file12",
      description:"helpPreTripTraining.description12",
      url: "download/PreTripTraining/IENA/MEDICAL INSURANCE/Brochure.pdf",
    },
    {
      name: "helpPreTripTraining.file13",
      description:"helpPreTripTraining.description13",
      url: "download/PreTripTraining/IENA/MEDICAL INSURANCE/CAP-Brochure-GN-CAPY24-min.pdf",
    },
    {
      name: "helpPreTripTraining.file14",
      description:"helpPreTripTraining.description14",
      url: "download/PreTripTraining/IENA/MEDICAL INSURANCE/COVID-19 EGI.pdf",
    },
    {
      name: "helpPreTripTraining.file15",
      description:"helpPreTripTraining.description15",
      url: "download/PreTripTraining/IENA/MEDICAL INSURANCE/INFO SEGURO DE SALUD IENA - ENVISAGE GLOBAL.pdf",
    },
  ];

  return (
    <>
      <h2 className="title">{t("general.preTripTraining")}</h2>
      <FilesTable files={files} />
    </>
  );
};

export default PreTripTraining;
